import Carousel from "react-bootstrap/Carousel";
import slide1 from "../assets/images/slides/kapolei 1.jpg";
import slide2 from "../assets/images/slides/kapolei 2.jpg";
import slide3 from "../assets/images/slides/kapolei 3.jpg";
import slide4 from "../assets/images/slides/kapolei 4.jpg";
import slide5 from "../assets/images/slides/kapolei 5.jpg";

function HeroImage() {
  return (
    <Carousel indicators={true} interval={5000} fade pause="false">
      <Carousel.Item>
        <img src={slide1} alt="kapolei" className="heroImage w-100" />
      </Carousel.Item>
      <Carousel.Item>
        <img src={slide2} alt="kapolei" className="heroImage w-100" />
      </Carousel.Item>
      <Carousel.Item>
        <img src={slide3} alt="kapolei" className="heroImage w-100" />
      </Carousel.Item>
      <Carousel.Item>
        <img src={slide4} alt="kapolei" className="heroImage w-100" />
      </Carousel.Item>
      <Carousel.Item>
        <img src={slide5} alt="kapolei" className="heroImage w-100" />
      </Carousel.Item>
    </Carousel>
  );
}

export default HeroImage;
