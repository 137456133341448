const shopInfo = {
  facebook:
    "https://www.facebook.com/people/Sacred-Art-Saratoga/100064005256986/",
  instagram: "https://www.instagram.com/sacredarttattoo_kapolei",
  yelp: "https://www.yelp.com/biz/sacred-art-tattoo-kapolei-kapolei-2?osq=Sacred+art+Kapolei",
  address: "563 Farrington Hwy #101, Kapolei, HI 96707",
  phone: "(808) 452-0407",
  title: "Sacred Art Tattoo Kapolei",
  about:
    "Sacred Art Kapolei is a proud member of the world-famous Sacred Art Tattoo family, serving Hawaii since 1992. Our shop is a clean and relaxed space where creativity thrives, offering guests a welcoming environment to collaborate with our talented artists.",
  about2:
    "Our shop provides a clean, laid-back environment where creativity thrives, allowing our talented artists to connect with guests and design one-of-a-kind tattoos in a wide range of styles. At Sacred Art, we’re all about delivering an unforgettable experience—combining artistry, connection, and a great cup of coffee.",
  email: "sales@sacredarthawaii.com",
  hours: "Cafe: 9 AM - 2 PM, Tattoo: 10 AM - 10 PM",
  map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3716.318542418784!2d-158.08008598462456!3d21.337927082526722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7c0063d4a35c3ecf%3A0x7d4a50bef786a9e5!2sSacred%20Art%20Tattoo%20and%20Coffee%20Bar%20-%20Kapolei!5e0!3m2!1sen!2sus!4v1679367099328!5m2!1sen!2sus",
  mapURL: "https://goo.gl/maps/m5LSgsCajdzumb7e8",
  contact: "Kapolei",
};

export default shopInfo;
