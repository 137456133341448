import turtles from "../assets/images/turtles.png";
import { FaFacebook, FaInstagram, FaYelp } from "react-icons/fa";
import shopInfo from "./shopInfo";
import { Helmet } from "react-helmet";
import FancyBox from "./FancyBox";
// import menu from "../assets/images/KapoleiMenu.jpg";
import main1 from "../assets/images/kapolei 1.jpg";
import store1 from "../assets/images/slides/kapolei 1.jpg";
import store2 from "../assets/images/slides/kapolei 2.jpg";
import store3 from "../assets/images/slides/kapolei 3.jpg";
import store4 from "../assets/images/slides/kapolei 4.jpg";
import store5 from "../assets/images/slides/kapolei 5.jpg";

function About() {
  <FancyBox />;
  const store = [store2, store3, store4, store5];

  return (
    <>
      <Helmet>
        <title>Sacred Art Tattoo Kapolei | Our Story</title>
      </Helmet>

      <div>
        <section id="about" className="d-flex about_area mt-60 mb-60">
          <div className="container">
            <div className="row justify-content-center mb-50">
              <div className="col-lg-6">
                <div className="section_title text-center pb-10">
                  <h4 className="title">Our Story</h4>
                  <img className="turtles" src={turtles} alt="turtles" />
                </div>
              </div>
            </div>
            <div className="about_content row">
              <div className="picCol">
                <div data-fancybox="store gallery" data-src={store1}>
                  <img src={main1} alt="about" className="clickable-image" />
                </div>
                {store.map((img) => {
                  return (
                    <div
                      className="d-none"
                      key={img}
                      data-fancybox="store gallery"
                      data-src={img}
                    >
                      <img src={img} alt="store" />
                    </div>
                  );
                })}
              </div>
              <div>
                <h5 className="about_title">About {shopInfo.title}</h5>
                <p className="about_text">{shopInfo.about}</p>
                {shopInfo.about2 ? (
                  <p className="about_text">{shopInfo.about2}</p>
                ) : (
                  ""
                )}
                {shopInfo.about3 ? (
                  <p className="about_text">{shopInfo.about3}</p>
                ) : (
                  ""
                )}
                {/* <p className="about_text pb-3">
                  Click to view our Coffee Bar menu:
                </p>
                <div data-fancybox data-src={menu}>
                  <img
                    src={menu}
                    className="img-thumbnail"
                    style={{ width: "10%", minWidth: "90px" }}
                    alt="menu"
                  />
                </div> */}
                <ul className="social">
                  <li>
                    <a href={shopInfo.facebook}>
                      <FaFacebook size={30} />
                    </a>
                  </li>
                  <li>
                    <a href={shopInfo.instagram}>
                      <FaInstagram size={30} />
                    </a>
                  </li>
                  <li>
                    <a href={shopInfo.yelp}>
                      <FaYelp size={30} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default About;
