import { Link } from "react-router-dom";
import turtles from "../assets/images/turtles.png";

function TextSection() {
  return (
    <div>
      <section id="about" className="d-flex about_area mt-60">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section_title text-center pb-10">
                <h3>THE LEADING TATTOO AND PIERCING SHOP ON OAHU</h3>
                <h5 className="mt-4">
                  Where ink, piercings, and community come together for an
                  unforgettable experience
                </h5>
                <img className="turtles" src={turtles} alt="turtles" />
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-4">
            <div className="col text-center">
              <p className="fst-italic fs-3 mb-3">Walk-ins Welcome</p>
              <Link to="/contact">
                <button className="main-btn" style={{ fontSize: "1.3rem" }}>
                  Book An Appointment
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TextSection;
